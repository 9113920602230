import React from 'react'
import './PoemList.css'

const PoemList = ({ poems }) => (
  <div className="poem-list">
    <div className="poems">
      {poems.map((p, poemIndex) => (
        <div key={poemIndex} className="p">
          <div className="text">
            {p.poem.split('\n').map((line, lineIndex) => (
              <p key={lineIndex}>{line}</p>
            ))}
          </div>
          <div className="author">- {p.author}</div>
        </div>
      ))}
    </div>
  </div>
)

export default PoemList
