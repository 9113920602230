import _ from 'lodash'
import React from 'react'

import './App.css'
import PoemForm from './PoemForm'
import PoemList from './PoemList'

const POEM_URL =
  'https://1hjhri4082.execute-api.us-east-1.amazonaws.com/dev/poem'

const App = () => {
  const [poemList, setPoemList] = React.useState([])
  const [shouldDisplayPoemForm, setShouldDisplayPoemForm] = React.useState(
    false
  )
  const [shouldDisplayTooltip, setShouldDisplayTooltip] = React.useState(false)

  React.useEffect(() => {
    fetch(POEM_URL)
      .then(res => res.json())
      .then(({ poems }) => {
        setPoemList(poems)
      })
  }, [])

  const handlePoemSubmission = async ({ poem, author }) => {
    const response = await fetch(POEM_URL, {
      method: 'POST',
      body: JSON.stringify({ author, poem })
    })
    const data = await response.json()
    setPoemList([data.poem, ...poemList])
    setShouldDisplayPoemForm(false)
  }

  return (
    <div className="wrapper">
      <div className="title">
        <h1 onClick={() => setShouldDisplayPoemForm(false)}>Wolfenheimer</h1>
      </div>
      {shouldDisplayPoemForm && (
        <PoemForm
          onCancel={() => setShouldDisplayPoemForm(false)}
          onSubmit={handlePoemSubmission}
        />
      )}
      {!shouldDisplayPoemForm && (
        <>
          <div className="create-poem">
            <i
              className="fas fa-info-circle"
              onMouseOver={() => setShouldDisplayTooltip(true)}
              onMouseLeave={() => setShouldDisplayTooltip(false)}
            />
            <div className={shouldDisplayTooltip ? 'tooltip' : 'hide'}>
              <p>
                A Wolfenheimer poem is one that contains 3 lines, 5 words per
                line, 60 letters, and at least one reference to an animal.
              </p>
            </div>
            <button
              className="button"
              onClick={() => setShouldDisplayPoemForm(true)}
            >
              Create a Wolfenheimer
            </button>
          </div>
          {_.isEmpty(poemList) && (
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <i className="fas fa-sync-alt fa-spin fa-7x" />
            </div>
          )}
          {!_.isEmpty(poemList) && <PoemList poems={poemList} />}
        </>
      )}
    </div>
  )
}

export default App
