export default [
  'aardwolf',
  'acanthaster',
  'accipitrinus',
  'acinynox',
  'acrantophis',
  'acridotheres',
  'acrobates',
  'actophilornis',
  'aculeatus',
  'adamanteus',
  'adjutant',
  'admiral',
  'adouri',
  'adsimilis',
  'aegypius',
  'aegyptiacus',
  'aepyceros',
  'aethereus',
  'aethiopicus',
  'aethiops',
  'aethiopus',
  'african',
  'africana',
  'africanus',
  'agama',
  'agassizii',
  'agelaius',
  'agile',
  'agilis',
  'agkistrodon',
  'agouti',
  'aguimp',
  'alba',
  'albatross',
  'albicollis',
  'albifrons',
  'albigularis',
  'albiventer',
  'albus',
  'alcelaphus',
  'alces',
  'alectura',
  'alligator',
  'alopex',
  'alopochen',
  'alouatta',
  'alpaca',
  'alpestris',
  'amazon',
  'amazona',
  'amblyrhynchus',
  'american',
  'americana',
  'americanus',
  'ammon',
  'ammospermophilus',
  'amphibius',
  'amphibolurus',
  'anaconda',
  'anas',
  'anastomus',
  'anathana',
  'anatinus',
  'and',
  'andean',
  'angasi',
  'angolensis',
  'anguitimens',
  'angustirostris',
  'anhinga',
  'anitibyx',
  'anser',
  'ant',
  'anteater',
  'antechinus',
  'antelope',
  'anthropoides',
  'antidorcas',
  'antigone',
  'antilocapra',
  'antilope',
  'aonyx',
  'apella',
  'aquila',
  'ara',
  'ararauna',
  'arboral',
  'arctic',
  'arcticus',
  'arctogalidia',
  'arctos',
  'ardea',
  'argalis',
  'armadillo',
  'armatus',
  'arnee',
  'asian',
  'asiatic',
  'asiatica',
  'ass',
  'astutus',
  'ateles',
  'ater',
  'atratus',
  'atricapillus',
  'atrococcineus',
  'aura',
  'aureus',
  'aurocapillus',
  'australian',
  'australis',
  'avocet',
  'avosetta',
  'away',
  'axis',
  'azaras',
  'baboon',
  'badger',
  'bahama',
  'bahamensis',
  'baileyi',
  'bald',
  'balearica',
  'baleen',
  'banded',
  'bandicoot',
  'barasingha',
  'barbata',
  'barbatus',
  'barbet',
  'bare-faced',
  'barking',
  'barren',
  'barrows',
  'bassariscus',
  'bat',
  'bat-eared',
  'bateleur',
  'beaded',
  'bear',
  'bearded',
  'beaver',
  'bee-eater',
  'beisa',
  'bengal',
  'bengalensis',
  'bengalus',
  'bennetts',
  'bent-toed',
  'bettong',
  'bettongia',
  'bicornis',
  'bighorn',
  'bird',
  'birds',
  'bison',
  'black',
  'black-backed',
  'black-capped',
  'black-cheeked',
  'black-collared',
  'black-crowned',
  'black-eyed',
  'black-faced',
  'black-footed',
  'black-fronted',
  'black-necked',
  'black-tailed',
  'black-throated',
  'black-winged',
  'blackbird',
  'blackbuck',
  'blackish',
  'blacksmith',
  'bleeding',
  'blesbok',
  'bleu',
  'blue',
  'blue-breasted',
  'blue-eared',
  'blue-faced',
  'blue-footed',
  'blue-tongued',
  'boa',
  'boar',
  'boat-billed',
  'bobcat',
  'bohor',
  'bonnet',
  'bontebok',
  'booby',
  'bos',
  'boselaphus',
  'bottle-nose',
  'boubou',
  'box',
  'brachyrhynchos',
  'bradypus',
  'branta',
  'brasilianus',
  'brasiliensis',
  'brazilian',
  'breviceps',
  'brindled',
  'brocket',
  'brolga',
  'brown',
  'brucel',
  'brunnea',
  'brush',
  'brush-tailed',
  'brushtail',
  'bubalornis',
  'bubalus',
  'bubo',
  'bubulcus',
  'buccinator',
  'bucephala',
  'buckeye',
  'bucorvus',
  'buffalo',
  'bugeranus',
  'bulbul',
  'bullockoides',
  'bunting',
  'burchelli',
  'burchells',
  'burmese',
  'burrowing',
  'buselaphus',
  'bush',
  'bushbaby',
  'bushbuck',
  'bushpig',
  'bustard',
  'butcher',
  'buteo',
  'butorides',
  'butterfly',
  'buttermilk',
  'caama',
  'cabassous',
  'cacatua',
  'caerulescens',
  'caffer',
  'caiman',
  'california',
  'californianus',
  'californicus',
  'caligata',
  'callipepla',
  'callorhinus',
  'callotis',
  'calura',
  'calyptorhynchus',
  'camel',
  'camelopardalis',
  'camelus',
  'campestris',
  'campestroides',
  'campo',
  'canada',
  'canadensis',
  'canadian',
  'cancrivorus',
  'caninus',
  'canis',
  'cape',
  'capensis',
  'capra',
  'capreolus',
  'capuchin',
  'capybara',
  'caracal',
  'caracara',
  'carbo',
  'cardinal',
  'carduelis',
  'caribou',
  'carmine',
  'carolina',
  'carpet',
  'carphophis',
  'caruncalatus',
  'casmerodius',
  'castor',
  'cat',
  'catfish',
  'catharacta',
  'cathartes',
  'catta',
  'cattle',
  'caudata',
  'cebus',
  'cenchria',
  'centrocercus',
  'cepapi',
  'cerastes',
  'ceratotherium',
  'cercatetus',
  'cercopithecus',
  'cereopsis',
  'certotrichas',
  'cervicapra',
  'cervus',
  'ceryle',
  'chacma',
  'chalcospilos',
  'chalybaeus',
  'chalybea',
  'chama',
  'chamaelo',
  'chameleon',
  'charadrius',
  'chauna',
  'chaus',
  'cheetah',
  'chelodina',
  'chestnut',
  'chickadee',
  'chilean',
  'chilensis',
  'chimachima',
  'chimpanzee',
  'chionis',
  'chipmunk',
  'chital',
  'chlamydosaurus',
  'chlidonias',
  'chloephaga',
  'chlorolepidotus',
  'chloroptera',
  'choloepus',
  'chordeiles',
  'choriotis',
  'chrysaetos',
  'chrysurus',
  'chuckwalla',
  'ciconia',
  'cinerea',
  'cinereoargenteus',
  'cinereus',
  'cinnmomeiventris',
  'civet',
  'clangula',
  'clarks',
  'clawless',
  'cliffchat',
  'climacteris',
  'coatimundi',
  'cobra',
  'cochlearius',
  'cocincinus',
  'cockatoo',
  'coendou',
  'coeruleoalba',
  'cokes',
  'cokii',
  'colaptes',
  'colchicus',
  'collared',
  'collaris',
  'colobus',
  'coluber',
  'columba',
  'columbian',
  'columbiana',
  'comb',
  'common',
  'concinnus',
  'concolor',
  'connochaetus',
  'conolophus',
  'conspicillatus',
  'constrictor',
  'cooki',
  'cooks',
  'coot',
  'coqui',
  'coracias',
  'corallus',
  'cordon',
  'cordylus',
  'corella',
  'corias',
  'cormorant',
  'corniculata',
  'coronatus',
  'corvus',
  'corythornis',
  'cottonmouth',
  'cottontail',
  'cougar',
  'couperi',
  'cow',
  'coyote',
  'crab',
  'crab-eating',
  'cracticus',
  'crake',
  'crane',
  'crassicaudata',
  'crassicaudataus',
  'crax',
  'creagrus',
  'creeper',
  'crested',
  'cricket',
  'crimson-breasted',
  'cristata',
  'cristatus',
  'crocodile',
  'crocodilus',
  'crocodylus',
  'crocuta',
  'crotalus',
  'crotaphytus',
  'crow',
  'crown',
  'crowned',
  'crumeniferus',
  'ctenophorus',
  'cuis',
  'cuniculata',
  'curlew',
  'currasow',
  'curve-billed',
  'curvirostre',
  'cygnus',
  'cynictis',
  'cynocephalus',
  'cynomys',
  'cyrtodactylus',
  'dabchick',
  'dacelo',
  'dactylatra',
  'dalli',
  'dama',
  'damaliscus',
  'dark-winged',
  'darter',
  'darwin',
  'dassie',
  'dasyprocta',
  'dasypus',
  'dasyurus',
  'decipiens',
  'deer',
  'defassa',
  'delphinus',
  'delphis',
  'dendrocitta',
  'dendrocygna',
  'dendrohyrax',
  'denhami',
  'denhams',
  'deroptyus',
  'desert',
  'deserti',
  'devil',
  'diamondback',
  'diceros',
  'dicrostonyx',
  'dicrurus',
  'didelphis',
  'dik',
  'dingo',
  'diomedea',
  'dipodomys',
  'dog',
  'dolichitus',
  'dolphin',
  'domesticus',
  'dominicanus',
  'dorcas',
  'dorsatum',
  'double-collared',
  'dove',
  'downy',
  'dragon',
  'dragonfly',
  'dromaeus',
  'dromedarius',
  'dromedary',
  'drongo',
  'drymarchon',
  'dubius',
  'duck',
  'duiker',
  'dunnart',
  'dusicyon',
  'dusky',
  'duvauceli',
  'dwarf',
  'eagle',
  'eastern',
  'eater',
  'ecaudatus',
  'echidna',
  'echimys',
  'egret',
  'egretta',
  'egyptian',
  'eira',
  'eland',
  'elaphus',
  'elegans',
  'elegant',
  'elephant',
  'elephantopus',
  'elephas',
  'eleven-banded',
  'elk',
  'ellioti',
  'emerald',
  'emerald-spotted',
  'emu',
  'emys',
  'entellus',
  'eolophus',
  'ephippiorhynchus',
  'ephipplorhynchus',
  'epicrates',
  'episcopus',
  'epops',
  'equinus',
  'equus',
  'eremophila',
  'erethizon',
  'erinaceus',
  'erythrocephalus',
  'erythronotos',
  'erythrorhyncus',
  'estrilda',
  'eubalaena',
  'eudromia',
  'eudyptula',
  'eugenii',
  'eumetopias',
  'eunectes',
  'eurasian',
  'euro',
  'eurocephalus',
  'european',
  'eutamias',
  'fairy',
  'falcinellus',
  'falco',
  'falcon',
  'false',
  'fasciatus',
  'fat-tailed',
  'feathertail',
  'felis',
  'feral',
  'ferret',
  'ferrugineus',
  'ferruginous',
  'fiber',
  'field',
  'finch',
  'fish',
  'fisher',
  'flamingo',
  'flats',
  'flavescens',
  'flavipes',
  'flavirostra',
  'flavirostris',
  'flaviventris',
  'flavus',
  'flicker',
  'flightless',
  'floridanus',
  'flycatcher',
  'flying',
  'foreset',
  'fork-tailed',
  'four-horned',
  'four-spotted',
  'four-striped',
  'fowl',
  'fox',
  'foxii',
  'francolin',
  'francolinus',
  'fratercula',
  'fregata',
  'frigate',
  'frilled',
  'fringe-eared',
  'frog',
  'frogmouth',
  'frontalis',
  'fruit',
  'fulgidus',
  'fulica',
  'fulicarius',
  'fuliginosus',
  'fulvus',
  'funambulus',
  'fur',
  'furcatus',
  'fuscata',
  'gabianus',
  'galago',
  'galah',
  'galapagoensis',
  'galapagos',
  'galerita',
  'galictis',
  'gallopavo',
  'gambelii',
  'gambels',
  'gambensis',
  'gaur',
  'gazella',
  'gazelle',
  'gazer',
  'gecko',
  'gekko',
  'gelada',
  'gemsbok',
  'genet',
  'genetta',
  'genoveua',
  'genoveva',
  'geochelone',
  'geococcyx',
  'geospiza',
  'gerbil',
  'gerbillus',
  'gerenuk',
  'giant',
  'giganteus',
  'gila',
  'giraffe',
  'girdled',
  'glama',
  'glareola',
  'glauca',
  'glider',
  'globicephala',
  'glossy',
  'gnu',
  'go',
  'goanna',
  'goat',
  'godeffroyi',
  'godwit',
  'gold',
  'gold-spotted',
  'golden',
  'golden-mantled',
  'goldeneye',
  'goliath',
  'golieth',
  'gonolek',
  'goose',
  'gopher',
  'gopherus',
  'gorilla',
  'gouazoubira',
  'gracilis',
  'granatina',
  'granti',
  'grants',
  'graspus',
  'grass',
  'gray',
  'great',
  'greater',
  'grebe',
  'green',
  'green-backed',
  'green-winged',
  'grenadier',
  'grey',
  'grey-footed',
  'greylag',
  'griffon',
  'grimma',
  'grison',
  'grizzly',
  'groenlandicus',
  'ground',
  'groundhog',
  'grouse',
  'grus',
  'guanaco',
  'guanicoe',
  'guerza',
  'guinea',
  'gularis',
  'gull',
  'gulls',
  'gutteralis',
  'gutturalis',
  'gymnocercus',
  'gymnorhina',
  'gyps',
  'haemastica',
  'haematodus',
  'haematopus',
  'hairy-nosed',
  'haje',
  'halcyon',
  'haliaeetus',
  'haliaetus',
  'hanuman',
  'harbor',
  'hare',
  'harrisi',
  'harrisii',
  'hartebeest',
  'hawk',
  'hawk-eagle',
  'hawk-headed',
  'heart',
  'hedgehog',
  'helmeted',
  'heloderma',
  'helogale',
  'hemionus',
  'hen',
  'hermaphroditus',
  'heron',
  'herpestes',
  'herring',
  'hexalepis',
  'highland',
  'himantopus',
  'hippopotamus',
  'hippotragus',
  'hoary',
  'hoffmani',
  'hoffmans',
  'hog',
  'honey',
  'hoopoe',
  'hornbill',
  'horned',
  'horridum',
  'hortulanus',
  'hottentot',
  'house',
  'howler',
  'hudsonian',
  'hudsonicus',
  'hummingbird',
  'huron',
  'hyaena',
  'hydrochaeris',
  'hydrochoerus',
  'hyena',
  'hymenolaimus',
  'hyrax',
  'hystrix',
  'ibex',
  'ibis',
  'ictalurus',
  'ictonyx',
  'iguana',
  'impala',
  'indian',
  'indica',
  'indigo',
  'insect',
  'intermedius',
  'inunguis',
  'irania',
  'irrorata',
  'isoodon',
  'jabiru',
  'jacana',
  'jackal',
  'jackrabbit',
  'jaeger',
  'jaguar',
  'jaguarundi',
  'jamaicensis',
  'japanese',
  'javan',
  'javanese',
  'javanicus',
  'jubatus',
  'jungle',
  'junonia',
  'kaffir',
  'kafue',
  'kalahari',
  'kangaroo',
  'kelp',
  'killer',
  'king',
  'kingfisher',
  'kingii',
  'kinkajou',
  'kirkii',
  'kirks',
  'kiskadee',
  'kite',
  'klipspringer',
  'knob-nosed',
  'koala',
  'kobus',
  'komodensis',
  'komodo',
  'kongoni',
  'kookaburra',
  'kori',
  'krameri',
  'kudu',
  'lagopus',
  'lama',
  'lamprotornis',
  'land',
  'langur',
  'laniarius',
  'laniaurius',
  'lappet-faced',
  'lapwing',
  'large',
  'large-eared',
  'lark',
  'larus',
  'lasiodora',
  'lasiorhinus',
  'lateralis',
  'lathami',
  'latifrons',
  'latrans',
  'laughing',
  'lava',
  'leadbeateri',
  'leadbeateris',
  'least',
  'leche',
  'lechwe',
  'legaan',
  'leipoa',
  'lemming',
  'lemur',
  'leo',
  'leonina',
  'leopard',
  'leopardus',
  'lepilemur',
  'leporina',
  'leprocaulinus',
  'leptoptilos',
  'leptoptilus',
  'lepus',
  'lesser',
  'leucocephala',
  'leucocephalus',
  'leucogaster',
  'leucopterus',
  'leucordia',
  'leucoryphus',
  'leucoscepus',
  'levaillants',
  'libellula',
  'libyca',
  'lightfoot',
  'lilac-breasted',
  'lily',
  'limnocorax',
  'limosa',
  'lion',
  'litrocranius',
  'little',
  'livia',
  'lizard',
  'llama',
  'lobatus',
  'long-billed',
  'long-crested',
  'long-finned',
  'long-necked',
  'long-nosed',
  'long-tailed',
  'longicausus',
  'longicollis',
  'lophoaetus',
  'lorikeet',
  'loris',
  'lory',
  'lorythaixoides',
  'lotor',
  'louisiadensis',
  'lourie',
  'lowland',
  'loxodonta',
  'lucifugus',
  'ludovicianus',
  'lunatus',
  'lupus',
  'lutra',
  'lybica',
  'lybius',
  'lycaon',
  'lycosa',
  'lynx',
  'mabuya',
  'macaca',
  'macao',
  'macaque',
  'macaw',
  'macropus',
  'maculatus',
  'madagascar',
  'madagascariensis',
  'madoqua',
  'magellanic',
  'magellanicus',
  'magister',
  'magistrate',
  'magnificans',
  'magnificent',
  'magnificus',
  'magpie',
  'mahali',
  'malabar',
  'malachite',
  'malacorhynchus',
  'malagasy',
  'malay',
  'mallard',
  'malleefowl',
  'manatee',
  'mandras',
  'manouria',
  'mantis',
  'mara',
  'marabou',
  'margay',
  'marine',
  'maritimus',
  'marmot',
  'marmota',
  'marshbird',
  'marsupialis',
  'marten',
  'martes',
  'masked',
  'maurus',
  'maximus',
  'mazama',
  'meadowlark',
  'meerkat',
  'megaderma',
  'megalotis',
  'melanaleucus',
  'melanerpes',
  'melanoptera',
  'melanotos',
  'melanura',
  'melas',
  'melba',
  'meleagris',
  'meles',
  'mellivora',
  'melophus',
  'melursus',
  'mendiculus',
  'mephitis',
  'merops',
  'mesomelas',
  'mexican',
  'mexicana',
  'mexicanus',
  'microcavia',
  'microcebus',
  'migrans',
  'milli',
  'milvago',
  'milvus',
  'miners',
  'minimus',
  'minor',
  'mirounga',
  'mississippi',
  'mississippiensis',
  'moccasin',
  'mocking',
  'mockingbird',
  'moluccanus',
  'monax',
  'mongoose',
  'monitor',
  'monkey',
  'monster',
  'moorhen',
  'moose',
  'morelia',
  'moschatus',
  'motacilla',
  'mouflon',
  'mountain',
  'mourning',
  'mouse',
  'mudskipper',
  'mulatta',
  'mule',
  'mungo',
  'mungos',
  'murinus',
  'musimon',
  'musk',
  'mustela',
  'mutus',
  'mycteria',
  'myiarchus',
  'mylampus',
  'mynah',
  'myotis',
  'myrmecobius',
  'myrmecophaga',
  'naja',
  'nannopterum',
  'narica',
  'nasua',
  'nasuta',
  'native',
  'nebouxii',
  'nectarinia',
  'nelson',
  'nelsoni',
  'nemestrina',
  'neophoca',
  'neophron',
  'neotis',
  'neotoma',
  'neotropic',
  'nerka',
  'nesomimus',
  'netted',
  'niger',
  'night',
  'nighthawk',
  'nigricans',
  'nigripes',
  'nigrivittatus',
  'nigroagularis',
  'nile',
  'nilgai',
  'niloticus',
  'nine-banded',
  'ninox',
  'nitens',
  'nivea',
  'norfolcensis',
  'north',
  'northern',
  'notechis',
  'novaeguineae',
  'novaehollandiae',
  'novemcinctus',
  'nubian',
  'nubicus',
  'nucifraga',
  'numbat',
  'numida',
  'nutcracker',
  'nuthatch',
  'nyala',
  'nyctanassa',
  'nyctea',
  'nyctereutes',
  'nycticorax',
  'obesulus',
  'obesus',
  'occidentalis',
  'occipitalis',
  'ocellata',
  'ocelot',
  'odocoilenaus',
  'odocoileus',
  'of',
  'old',
  'olive',
  'onager',
  'onca',
  'oncorhynchus',
  'onocratalus',
  'openbill',
  'opossum',
  'or',
  'orangutan',
  'orca',
  'orcinus',
  'oreamnos',
  'oreotragus',
  'oribi',
  'oriental',
  'orientalis',
  'ornate',
  'ornatus',
  'ornithorhynchus',
  'oryx',
  'oscitans',
  'osprey',
  'ostrich',
  'otaria',
  'otocyon',
  'otter',
  'ourebi',
  'ourebia',
  'ovenbird',
  'ovibos',
  'ovis',
  'owl',
  'ox',
  'oxybelis',
  'oystercatcher',
  'paca',
  'pacific',
  'pacificus',
  'pacos',
  'paddy',
  'pademelon',
  'paena',
  'painted',
  'pale',
  'pale-throated',
  'pallass',
  'pallidus',
  'palm',
  'palumbus',
  'pampa',
  'pan',
  'pandon',
  'paniscus',
  'panthera',
  'papa',
  'papilio',
  'papio',
  'paradisaea',
  'paradisea',
  'paradoxure',
  'paradoxurus',
  'parahybana',
  'parakeet',
  'paraxerus',
  'pardalis',
  'pardus',
  'paroaria',
  'parrot',
  'parryi',
  'parryii',
  'partridge',
  'parus',
  'passer',
  'patagonum',
  'patch-nosed',
  'pavo',
  'pavonina',
  'peacock',
  'pecari',
  'peccary',
  'pedetes',
  'pelecans',
  'pelecanus',
  'pelican',
  'penguin',
  'penicillata',
  'pennanti',
  'pennati',
  'perameles',
  'percnopterus',
  'peregrine',
  'peregrinus',
  'persica',
  'petaurus',
  'phacochoerus',
  'phaethon',
  'phalacrocorax',
  'phalarope',
  'phalaropus',
  'phascogale',
  'phascolarctos',
  'phasianus',
  'pheasant',
  'philetairus',
  'phoca',
  'phoeniceus',
  'phoeniconaias',
  'phoenicopterus',
  'phylurus',
  'physignathus',
  'picoides',
  'pictus',
  'pie',
  'pied',
  'pig-tailed',
  'pigeon',
  'pilot',
  'pine',
  'pink',
  'pintail',
  'pinus',
  'piscivorus',
  'pitangus',
  'pituophis',
  'plains',
  'planci',
  'platalea',
  'platypus',
  'platyrhynchos',
  'plectopterus',
  'plegadis',
  'plocepasser',
  'ploceus',
  'plover',
  'pocket',
  'podargus',
  'polar',
  'polecat',
  'porcupine',
  'porcus',
  'porphyrelophus',
  'porphyrio',
  'possum',
  'potamochoerus',
  'potoroo',
  'potorous',
  'potos',
  'prairie',
  'praying',
  'prehensile-tailed',
  'prehensilis',
  'priodontes',
  'prionace',
  'procyon',
  'procyonoides',
  'pronghorn',
  'propithecus',
  'proteles',
  'pseudalopex',
  'pseudocheirus',
  'pseudoleistes',
  'psittacula',
  'psophia',
  'pterocles',
  'pteronura',
  'pteropus',
  'pubescens',
  'puffin',
  'puku',
  'puma',
  'pumilio',
  'puna',
  'punctata',
  'purple',
  'pycnonotus',
  'pygmaeus',
  'pygmy',
  'python',
  'pytilia',
  'quadricornis',
  'quadrimaculata',
  'quail',
  'quoll',
  'rabbit',
  'raccoon',
  'racer',
  'radiata',
  'radiated',
  'rainbow',
  'ramphastos',
  'rana',
  'rangifer',
  'raphicerus',
  'rat',
  'rattlesnake',
  'ratufa',
  'raven',
  'recurvirostra',
  'red',
  'red-billed',
  'red-breasted',
  'red-capped',
  'red-cheeked',
  'red-eyed',
  'red-headed',
  'red-knobbed',
  'red-legged',
  'red-necked',
  'red-shouldered',
  'red-tailed',
  'red-winged',
  'redunca',
  'reedbuck',
  'regalis',
  'reindeer',
  'rhabdomys',
  'rhea',
  'rhesus',
  'rhinoceros',
  'richardsonii',
  'richardsons',
  'ridgwayi',
  'right',
  'ring',
  'ring-necked',
  'ring-tailed',
  'ringtail',
  'river',
  'roadrunner',
  'roan',
  'robertsi',
  'robin',
  'robustus',
  'rock',
  'roe',
  'roller',
  'rose-ringed',
  'roseat',
  'roseate',
  'roseicapillus',
  'royal',
  'ruber',
  'rubicundus',
  'rubiginosus',
  'rudis',
  'rufa',
  'rufescens',
  'ruficollis',
  'rufogriseus',
  'rufous',
  'rufous-collared',
  'rufus',
  'russian',
  'sable',
  'sacred',
  'saddle-billed',
  'sage',
  'sagittarius',
  'sally',
  'salmon',
  'salvadora',
  'salvator',
  'sambar',
  'sandgrouse',
  'sandhill',
  'sandpiper',
  'sarcophilus',
  'sarcorhamphus',
  'sarkidornis',
  'sarus',
  'sauromalus',
  'savanna',
  'savannah',
  'scaly-breasted',
  'scandiaca',
  'scarlet',
  'sceloporus',
  'scincoides',
  'sciurus',
  'scolopax',
  'scottish',
  'screamer',
  'scriptus',
  'scrofa',
  'scrub',
  'sea',
  'seal',
  'secretary',
  'seiurus',
  'semmiannulatus',
  'semnopithecus',
  'senegalensis',
  'seniculus',
  'septemcincus',
  'serpentarius',
  'serval',
  'seven-banded',
  'shark',
  'sheathbill',
  'sheep',
  'shelduck',
  'short-beaked',
  'short-clawed',
  'short-nosed',
  'shrew',
  'shrike',
  'sidewinder',
  'sifaka',
  'silver',
  'silver-backed',
  'silvestris',
  'simum',
  'siskin',
  'sitta',
  'skimmer',
  'skink',
  'skua',
  'skunk',
  'slender',
  'slender-billed',
  'sloth',
  'small',
  'small-clawed',
  'small-spotted',
  'small-toothed',
  'smithopsis',
  'smiths',
  'smyrnesis',
  'snake',
  'snake-necked',
  'snow',
  'snowy',
  'snycerus',
  'sociable',
  'socius',
  'sockeye',
  'south',
  'southern',
  'sp.',
  'sparrow',
  'spasma',
  'spectacled',
  'speothos',
  'speotyte',
  'spermophilus',
  'spheniscus',
  'spider',
  'spilogale',
  'spilogaster',
  'spilotes',
  'spiny',
  'spizaetus',
  'spoonbill',
  'sportive',
  'spotted',
  'spotted-tailed',
  'springbok',
  'springbuck',
  'springhare',
  'spur-winged',
  'spurfowl',
  'square-lipped',
  'squirrel',
  'stanley',
  'star',
  'starfish',
  'starling',
  'steenbok',
  'steenbuck',
  'steller',
  'stellers',
  'stenella',
  'stercorarius',
  'sterna',
  'stick',
  'stigmatica',
  'stilt',
  'stone',
  'stonei',
  'stork',
  'strepsiceros',
  'streptopelia',
  'striated',
  'striatus',
  'strigoides',
  'striped',
  'struthio',
  'subcristatus',
  'sugar',
  'sula',
  'sulfur-crested',
  'sulphuratus',
  'sun',
  'sunbird',
  'sungazer',
  'superb',
  'superbus',
  'superciliaris',
  'suricata',
  'suricate',
  'suricatta',
  'sus',
  'swainsonii',
  'swainsons',
  'swallow',
  'swallow-tail',
  'swallowtail',
  'swamp',
  'swan',
  'sylvicapra',
  'sylvilagus',
  'tachybaptus',
  'tachyglossus',
  'tadorna',
  'tailless',
  'tajacu',
  'tamandua',
  'tamiasciurus',
  'tammar',
  'tanager',
  'tapir',
  'tapirus',
  'tapoatafa',
  'tarandus',
  'tarantula',
  'tardigratus',
  'tasmanian',
  'tauraco',
  'taurinus',
  'taurotagus',
  'taurus',
  'tawny',
  'taxidea',
  'taxus',
  'tayassu',
  'tayra',
  'teal',
  'tenrec',
  'tenuirostris',
  'terathopius',
  'tern',
  'terrapene',
  'terrestris',
  'tetracerus',
  'tetradactyla',
  'thalasseus',
  'thamnolaea',
  'theropithecus',
  'thirteen-lined',
  'thompsonii',
  'thomsons',
  'thorns',
  'thous',
  'thrasher',
  'three-banded',
  'three-toed',
  'threskionis',
  'thula',
  'thylogale',
  'tibicen',
  'tiger',
  'tigris',
  'tiliqua',
  'timber',
  'tinamou',
  'tockus',
  'toddy',
  'tokay',
  'topi',
  'torquata',
  'torquatus',
  'tortoise',
  'toucan',
  'townsendii',
  'toxostoma',
  'tracheliotus',
  'trachyphonus',
  'tragelaphus',
  'tragocamelus',
  'tree',
  'trichechus',
  'trichoglossus',
  'trichosurus',
  'tricollaris',
  'tridactyla',
  'tridactylus',
  'tridecemlineatus',
  'trifasciatus',
  'tringa',
  'triseriatus',
  'tristis',
  'trivirgata',
  'troglodytes',
  'tropic',
  'tropical',
  'trotter',
  'trumpeter',
  'truncatus',
  'tsessebe',
  'tuberculifer',
  'tucanus',
  'turaco',
  'turkey',
  'tursiops',
  'turtle',
  'turtur',
  'two-banded',
  'two-toed',
  'tyrant',
  'tyto',
  'uinta',
  'unavailable',
  'undulata',
  'unicolor',
  'upupa',
  'uraeginthus',
  'urial',
  'urophasianus',
  'uropygialis',
  'ursinus',
  'ursus',
  'vagabunda',
  'vaillantii',
  'vampire',
  'vanaticus',
  'vanellus',
  'vanessa',
  'varanus',
  'vardoni',
  'vardonii',
  'variegata',
  'varius',
  'verreauxi',
  'verreauxs',
  'vervet',
  'vicugna',
  'vicuna',
  'viduata',
  'vine',
  'violacea',
  'violet-crested',
  'violet-eared',
  'viper',
  'vipera',
  'virescens',
  'virginia',
  'virginiana',
  'virginianus',
  'viridis',
  'vittata',
  'vitulina',
  'viverrinus',
  'vocifer',
  'vombatus',
  'vulgaris',
  'vulpecula',
  'vulpes',
  'vulture',
  'wagtail',
  'wallaby',
  'wallaroo',
  'walleri',
  'wambenger',
  'wapiti',
  'warthog',
  'water',
  'waterbuck',
  'wattled',
  'waved',
  'waxbill',
  'weaver',
  'weeper',
  'weidi',
  'western',
  'whale',
  'whip-tailed',
  'whistling',
  'white',
  'white-backed',
  'white-bellied',
  'white-browed',
  'white-cheeked',
  'white-crowned',
  'white-eye',
  'white-faced',
  'white-fronted',
  'white-headed',
  'white-lipped',
  'white-mantled',
  'white-necked',
  'white-nosed',
  'white-rumped',
  'white-tailed',
  'white-throated',
  'white-winged',
  'wiedi',
  'wild',
  'wildebeest',
  'wolf',
  'wombat',
  'wood',
  'woodchuck',
  'woodcock',
  'woodpecker',
  'woodrat',
  'woolly-necked',
  'world',
  'worm',
  'woylie',
  'xerus',
  'yagouaroundi',
  'yak',
  'yellow',
  'yellow-bellied',
  'yellow-billed',
  'yellow-brown',
  'yellow-crowned',
  'yellow-headed',
  'yellow-necked',
  'yellow-rumped',
  'yellow-throated',
  'zalophus',
  'zebra',
  'zenaida',
  'zonotrichia',
  'zorilla',
  'zorro',
  'zosterops'
]
