import _ from 'lodash'
import React from 'react'

import animals from './animals'
import './PoemForm.css'

const CHECK = 'far fa-check-square'
const SQUARE = 'far fa-square'
const TOTAL_LINES = 3
const TOTAL_LETTERS = 60
const WORDS_PER_LINE = 5

const PoemForm = ({ onCancel, onSubmit }) => {
  const [poem, setPoem] = React.useState('')
  const [author, setAuthor] = React.useState('')

  const lines = splitLines(poem)
  const numLetters = letterCount(poem)
  const validLetterCount = numLetters === TOTAL_LETTERS
  const validLineCount = lines.length === TOTAL_LINES
  const validWordsPerLine = containsWordsPerLine(
    poem,
    WORDS_PER_LINE,
    TOTAL_LINES
  )
  const includesAnimal = React.useMemo(() => poem.split(/\W/g).some(isAnimal), [
    poem
  ])
  const validPoem = React.useMemo(
    () =>
      validLetterCount && validLineCount && validWordsPerLine && includesAnimal,
    [validLetterCount, validLineCount, validWordsPerLine, includesAnimal]
  )

  React.useEffect(() => {
    // bonus: prevent the user from having more than 3 lines
    if (lines.length > TOTAL_LINES) {
      const [first, second, third] = lines
      setPoem([first, second, third].join('\n'))
    }
  }, [lines])

  const handleChange = event => {
    setPoem(event.target.value)
  }

  const handleSubmit = () => onSubmit({ poem, author })

  return (
    <>
      <div className="poem">
        <textarea
          name="poem"
          onChange={handleChange}
          value={poem}
          placeholder="Put your Wolfenheimer here..."
        />
        <div className="form-row">
          <input
            onChange={e => setAuthor(e.target.value)}
            type="text"
            name="author"
            value={author}
            placeholder="Author (that's you)"
          />
        </div>
        <div>
          <button
            className="button"
            disabled={!validPoem}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button className="button" onClick={() => onCancel()}>
            Cancel
          </button>
        </div>
      </div>
      <div className="instructions">
        <p>A Wolfenheimer is a poem that:</p>
        <ul>
          <li>
            <i className={icon(validLetterCount)} /> is exactly {TOTAL_LETTERS}{' '}
            letters ({TOTAL_LETTERS - numLetters} left){' '}
          </li>
          <li>
            <i className={icon(validLineCount)} /> is exactly {TOTAL_LINES}{' '}
            lines ({TOTAL_LINES - lines.length} lines left){' '}
          </li>
          <li>
            <i className={icon(validWordsPerLine)} /> has exactly{' '}
            {WORDS_PER_LINE} words per line
          </li>
          <li>
            <i className={icon(includesAnimal)} /> mentions an animal
          </li>
        </ul>
      </div>
    </>
  )
}

const icon = val => (val ? CHECK : SQUARE)
const letterCount = str => _.get(matchLetters(str), 'length', 0)
const isAnimal = word => {
  if (animals.includes(word.toLowerCase().trim())) return true
  if (word.endsWith('s'))
    return animals.includes(_.trimEnd(word.toLowerCase(), 's').trim())
  return false
}
const letterRegex = /[a-zA-Z]/gm
const matchLetters = str => str.match(letterRegex)
const splitLines = str => str.split('\n') || 0
const containsWordsPerLine = (str, wordsPerLine, totalLines) =>
  splitLines(str)
    .map(wordCount)
    .filter(count => count === wordsPerLine).length === totalLines
const wordCount = str => str.split(' ').filter(n => n !== '').length

export default PoemForm
